import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICollecting, IKeyword, IStockKeyword } from "./types/_keyword";
const api = new APIClient();

const path = '/keywords';
const keywordApi = {
  keywords(params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  keywordsMonitor(params: any, options = null): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}/monitor`
    return api.get(url, params, options)
  },
  keywordsSearchNews(params: any, options = null): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/music/ranking`
    return api.get(url, params, options)
  },
  rankKeywordSports(params: any, options = null): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/rank-sport/ranking`
    return api.get(url, params, options)
  },
  rankKeywordCars(params: any, options = null): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/rank-car/ranking`
    return api.get(url, params, options)
  },
  rankKeywordGames(params: any, options = null): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/rank-game/ranking`
    return api.get(url, params, options)
  },
  downloadExcelKeywords(params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}/monitor/export`
    return api.get(url, params)
  },
  stockKeywords(params: any): Promise<AxiosResponse<ResponseData<IStockKeyword[]> & PaginationResponse, any>> {
    const url = `/stock-statistics`
    return api.get(url, params)
  },
  keywordsByCategory(categoryId: string | number, params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/categories/${categoryId}/keywords`
    return api.get(url, params)
  },
  collecting(params: any): Promise<AxiosResponse<ResponseData<ICollecting[]> & PaginationResponse, any>> {
    const url = `/statistics/keywords/crawl-logs`
    return api.get(url, params)
  },
  newsSearchingKeyword(params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}/analysis-news`
    return api.get(url, params)
  },
  getKeywordSummariesStatus(params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/status/summary`;
    return api.get(url, params)
  },
  getRankingMonitorSummaries(params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/monitor/count`;
    return api.get(url, params)
  },
  getQueryInfoApprove(params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/monitor/admin-confirm`;
    return api.get(url, params)
  },
  getKeyword(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  postKeyword(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putKeyword(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteKeyword(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, { data: dataForm })
  },
  deleteKeywordOfCategories(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/${id}/categories`;
    return api.delete(url, { data: dataForm })
  },
  postKeywordExclude(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/exclude`;
    return api.post(url, dataForm)
  },
  deleteKeywordExclude(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/exclude`;
    return api.delete(url, { data: dataForm })
  },
  postKeywordHide(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/hide`;
    return api.post(url, dataForm)
  },
  deleteKeywordHide(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/hide`;
    return api.delete(url, { data: dataForm })
  },
  postKeywordActive(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/active`;
    return api.post(url, dataForm)
  },
  addConfirmKeyword(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/confirm`;
    return api.post(url, dataForm)
  },
  cancelConfirmKeyword(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/confirm`;
    return api.delete(url, { data: dataForm })
  },
  putInfoExtractKeyword(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/${id}/info-extract`;
    return api.put(url, dataForm)
  },
  postKeywordRaw(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `${path}/keyword-search-raw`;
    return api.post(url, dataForm)
  },
  getRankKeyword(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/${id}`;
    return api.get(url, params)
  },
  putRankKeywordRaw(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/${id}`;
    return api.put(url, dataForm)
  },
  putNationalityRankKeywordRaw(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/${id}/nationality`;
    return api.put(url, dataForm)
  },
  getQueryInfoApproveRankKeyword(params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/confirm`;
    return api.get(url, params)
  },
  addConfirmRankKeyword(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/confirm`;
    return api.post(url, dataForm)
  },
  putInfoSearchKeyword(id: string | number, dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/${id}/search-keyword`;
    return api.put(url, dataForm)
  },
  keywordTrends(params: any): Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `/keyword-trends`
    return api.get(url, params)
  },
  keywordTrendNews(params: any = {}): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const clone = structuredClone(params);
    delete clone.id;
    const url = `/keyword-trends/${params?.id}/news`;
    return api.get(url, clone)
  },
  postGemma(dataForm: any = { prompt: '' }): Promise<AxiosResponse<ResponseData<{ prompt: string }>, any>> {
    const url = `/keyword-trends/gemma2`;
    return api.post(url, {
      host: "http://218.145.67.34:11434",
      ...dataForm
    })
  },
  postKeywordRankExclude(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/exclude`;
    return api.post(url, dataForm)
  },
  deleteKeywordRankExclude(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/exclude`;
    return api.delete(url, { data: dataForm })
  },
  postKeywordRankHide(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/hide`;
    return api.post(url, dataForm)
  },
  postKeywordRankActivate(dataForm: any): Promise<AxiosResponse<ResponseData<IKeyword>, any>> {
    const url = `/rank-keyword/active`;
    return api.post(url, dataForm)
  },
}
export default keywordApi;
