import { Option } from "api/types/_public";

export const SCHEDULE_OPTIONS: Option[] = [
  {
    label: "Now",
    value: "now",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const SCHEDULE_MONTHLY_OPTIONS: Option[] = Array.from(
  { length: 31 },
  (_, index) => {
    const day = (index + 1).toString().padStart(2, "0");
    return { label: `${day}th`, value: day };
  }
);

export const ARR_INDEX_TEXT_DAY_OF_WEEK: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const SCHEDULE_WEEKLY_OPTIONS: Option[] = [
  {
    label: "Monday",
    value: "1",
  },
  {
    label: "Tuesday",
    value: "2",
  },
  {
    label: "Wednesday",
    value: "3",
  },
  {
    label: "Thursday",
    value: "4",
  },
  {
    label: "Friday",
    value: "5",
  },
  {
    label: "Saturday",
    value: "6",
  },
  {
    label: "Sunday",
    value: "7",
  },
];

export const TYPE_SHOW_MORE_HASHTAG = {
  SCROLL: 'scroll',
  MODAL: 'modal'
}

export const STATUS_TREND_INDEX_OPTIONS: Option[] = [
  {
    label: "Status_Trend_Index_1", // IDLE
    value: "1",
  },
  {
    label: "Status_Trend_Index_2", // RUNNING
    value: "2",
  },
  {
    label: "Status_Trend_Index_99", // ERROR
    value: "99",
  }
];

export enum ENUM_STATUS_KEYWORD_RANKING {
  CANCEL_EXCLUDED = -1,
  CANCEL_HIDDEN = -2,
  ACTIVATED = 0,
  EXCLUDED_OR_HIDDEN = 1,
  EXCLUDED = 2,
  HIDDEN = 3,
};

export const STATUS_KEYWORD_RANKING_OPTIONS: Option[] = [
  // {
  //   label: `Status_Keyword_Ranking_${ENUM_STATUS_KEYWORD_RANKING.ACTIVATED}`, // Activated
  //   value: `${ENUM_STATUS_KEYWORD_RANKING.ACTIVATED}`,
  // },
  // {
  //   label: `Status_Keyword_Ranking_${ENUM_STATUS_KEYWORD_RANKING.EXCLUDED_OR_HIDDEN}`, // Excluded
  //   value: `${ENUM_STATUS_KEYWORD_RANKING.EXCLUDED_OR_HIDDEN}`,
  // },
  {
    label: `Activated`, // Activated
    value: `1`,
  },
  {
    label: `Excluded`, // Exclude
    value: `2`,
  },
  {
    label: `Hidden`, // Hide
    value: `3`,
  },
];

export enum ENUM_STATUS_CHART_KEYWORD_RANKING {
  ACTIVATED = 1,
  HIDDEN = 2,
  EXCLUDED = 3,
};

export const COLORS_STATUS_KEYWORD_CHART_RANKING: any = {
  '0': 'info',
  '1': 'success',
  '2': 'warning',
  '3': 'danger',
};

export const STATUS_KEYWORD_CHART_RANKING_OPTIONS: Option[] = [
  {
    label: `Status_Keyword_Chart_Ranking_${ENUM_STATUS_CHART_KEYWORD_RANKING.ACTIVATED}`, // Activated
    value: `${ENUM_STATUS_CHART_KEYWORD_RANKING.ACTIVATED}`,
  },
  {
    label: `Status_Keyword_Chart_Ranking_${ENUM_STATUS_CHART_KEYWORD_RANKING.HIDDEN}`, // Hide
    value: `${ENUM_STATUS_CHART_KEYWORD_RANKING.HIDDEN}`,
  },
  {
    label: `Status_Keyword_Chart_Ranking_${ENUM_STATUS_CHART_KEYWORD_RANKING.EXCLUDED}`, // Excluded
    value: `${ENUM_STATUS_CHART_KEYWORD_RANKING.EXCLUDED}`,
  },
];


export enum MUSIC_PLATFORM {
  MELON = 1,
  VIBE = 2,
  GENIE = 3,
  BUGS = 4,
  FLO = 5,
}

export const TYPE_SOURCE_CHART_RANKING_OPTIONS: Option[] = [
  {
    label: `Tab Melon`, // Melon
    value: `${MUSIC_PLATFORM.MELON}`,
  },
  {
    label: `Tab Vibe`, // Vibe
    value: `${MUSIC_PLATFORM.VIBE}`,
  },
  {
    label: `Tab Genie`, // Genie
    value: `${MUSIC_PLATFORM.GENIE}`,
  },
  {
    label: `Tab Bugs`, // Bugs
    value: `${MUSIC_PLATFORM.BUGS}`,
  },
  {
    label: `Tab FLO`, // FLO
    value: `${MUSIC_PLATFORM.FLO}`,
  },
];


export enum LEAGUE_CODE {
  KPGA = 'kpga',
  KLPGA = 'klpga',
}

export const LEAGUE_CODE_CHART_RANKING_OPTIONS: Option[] = [
  {
    label: `Tab kpga`, // kpga
    value: `${LEAGUE_CODE.KPGA}`,
  },
  {
    label: `Tab klpga`, // klpga
    value: `${LEAGUE_CODE.KLPGA}`,
  },
];

export enum BASEBALL_PLAYER {
  HITTER = 'hitter',
  PITCHER = 'pitcher',
}


export const BASEBALL_PLAYER_CHART_RANKING_OPTIONS: Option[] = [
  {
    label: `Tab hitter`, // hitter
    value: `${BASEBALL_PLAYER.HITTER}`,
  },
  {
    label: `Tab pitcher`, // pitcher
    value: `${BASEBALL_PLAYER.PITCHER}`,
  },
];

export enum SOCCER_PLAYER {
  SOCCER = '1',
}

export const SOCCER_PLAYER_CHART_RANKING_OPTIONS: Option[] = [
  {
    label: `Tab soccer`, // soccer
    value: `${SOCCER_PLAYER.SOCCER}`,
  },
];

export enum CAR_RANKING_TYPE {
  BRAND = '1',
  MODEL = '2',
}

export const CAR_RANKING_TYPE_OPTIONS: Option[] = [
  {
    label: `Tab Brand`, // Brand
    value: `${CAR_RANKING_TYPE.BRAND}`,
  },
  {
    label: `Tab Model`, // Model
    value: `${CAR_RANKING_TYPE.MODEL}`,
  },
];

export const STATUS_ALERT_MODE_OPTIONS: Option[] = [
  {
    label: "Status_Alert_Mode_0", // SILENT
    value: "0",
  },
  {
    label: "Status_Alert_Mode_1", // NORMAL
    value: "1",
  }
];

export const STATUS_MEDIA_OPTIONS: Option[] = [
  {
    label: "Status_Media_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Media_1", // Active
    value: "1",
  }
];

export const STATUS_MEMBER_OPTIONS: Option[] = [
  {
    label: "Status_Member_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Member_1", // Active
    value: "1",
  }
];

export enum MEMBER_TYPE {
  REGULAR = 'regular',
  BUSINESS = 'business',
}

export const TYPE_MEMBER_OPTIONS: Option[] = [
  {
    label: MEMBER_TYPE.REGULAR, // Regular
    value: MEMBER_TYPE.REGULAR,
  },
  {
    label: MEMBER_TYPE.BUSINESS, // Business
    value: MEMBER_TYPE.BUSINESS,
  }
];

export const STATUS_ACCOUNT_OPTIONS: Option[] = [
  {
    label: "Status_Account_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Account_1", // Active
    value: "1",
  }
];


export const STATUS_PARTICIPATION_TEMPLATE_OPTIONS: Option[] = [
  {
    label: "Status_Template_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Template_1", // Active
    value: "1",
  }
];

export const STATUS_PARTICIPATION_TICKET_OPTIONS: Option[] = [
  {
    label: "Status_Ticket_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Ticket_1", // Active
    value: "1",
  }
];

export const STATUS_SUBSCRIPTION_OPTIONS: Option[] = [
  {
    label: "Status_Subscription_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Subscription_1", // Active
    value: "1",
  }
];

export enum PAYMENT_PROCESS_STATUS {
  INITIATED = 1, // Initial
  PAYMENT_SUCCESS = 10, // Payment success
  CONFIRM_USER_CARD_SUCCESS = 20, // Confirm user card success
  PAYMENT_VERIFIED = 21, // Payment verified
  CONFIRM_USER_CARD_ERROR = 25, // Confirm user card error
  REQUEST_BILL_ERROR = 30, // Request bill error
  PAY_CANCELED = 40, // User has canceled pay.
  PAYMENT_ERROR = 50, // Payment error
}
const getColors = () => {
  const colors = [];
  colors[PAYMENT_PROCESS_STATUS.INITIATED] = 'dark';
  colors[PAYMENT_PROCESS_STATUS.PAYMENT_SUCCESS] = 'success';
  colors[PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_SUCCESS] = 'primary';
  colors[PAYMENT_PROCESS_STATUS.PAYMENT_VERIFIED] = 'primary';
  colors[PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_ERROR] = 'warning';
  colors[PAYMENT_PROCESS_STATUS.REQUEST_BILL_ERROR] = 'warning';
  colors[PAYMENT_PROCESS_STATUS.PAY_CANCELED] = 'danger';
  colors[PAYMENT_PROCESS_STATUS.PAYMENT_ERROR] = 'danger';
  return colors;
};

export const COLOR_STATUS_PAYMENT = getColors();

export const STATUS_PAYMENT_OPTIONS: Option[] = [
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.PAYMENT_SUCCESS}`,
    value: String(PAYMENT_PROCESS_STATUS.PAYMENT_SUCCESS),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.PAYMENT_ERROR}`,
    value: String(PAYMENT_PROCESS_STATUS.PAYMENT_ERROR),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.PAY_CANCELED}`,
    value: String(PAYMENT_PROCESS_STATUS.PAY_CANCELED),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.INITIATED}`,
    value: String(PAYMENT_PROCESS_STATUS.INITIATED),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_SUCCESS}`,
    value: String(PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_SUCCESS),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.PAYMENT_VERIFIED}`,
    value: String(PAYMENT_PROCESS_STATUS.PAYMENT_VERIFIED),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_ERROR}`,
    value: String(PAYMENT_PROCESS_STATUS.CONFIRM_USER_CARD_ERROR),
  },
  {
    label: `Status_Payment_${PAYMENT_PROCESS_STATUS.REQUEST_BILL_ERROR}`,
    value: String(PAYMENT_PROCESS_STATUS.REQUEST_BILL_ERROR),
  },
];

export enum SUBSCRIPTION_TYPE {
  COMPANY = 1,
  PEOPLE = 2,
  STOCK = 3,
}
export const TYPE_SUBSCRIPTION_OPTIONS: Option[] = [
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.COMPANY}`,
    value: String(SUBSCRIPTION_TYPE.COMPANY),
  },
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.PEOPLE}`,
    value: String(SUBSCRIPTION_TYPE.PEOPLE),
  },
  {
    label: `Type_Subscription_${SUBSCRIPTION_TYPE.STOCK}`,
    value: String(SUBSCRIPTION_TYPE.STOCK),
  },
];

export enum PLAN_TYPE {
  STARTER = 1,
  LIGHT = 2,
  STANDARD = 3,
  PREMIUM = 4,
  ENTERPRISE = 5,
};

export const PLAN_TYPE_SUBSCRIPTION_OPTIONS: Option[] = [
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.STARTER}`,
    value: String(PLAN_TYPE.STARTER),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.LIGHT}`,
    value: String(PLAN_TYPE.LIGHT),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.STANDARD}`,
    value: String(PLAN_TYPE.STANDARD),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.PREMIUM}`,
    value: String(PLAN_TYPE.PREMIUM),
  },
  {
    label: `Plan_Type_Subscription_${PLAN_TYPE.ENTERPRISE}`,
    value: String(PLAN_TYPE.ENTERPRISE),
  },
];


export const TYPE_PARTICIPATION_TEMPLATE_IS_VOTE = "1";
export const TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY = "2";

export const TYPE_PARTICIPATION_TEMPLATE_OPTIONS: Option[] = [
  {
    label: "Vote", // Vote
    value: TYPE_PARTICIPATION_TEMPLATE_IS_VOTE,
  },
  {
    label: "Survey", // Survey
    value: TYPE_PARTICIPATION_TEMPLATE_IS_SURVEY,
  }
];


export enum PRODUCT_TYPE {
  TICKET = 1,
  SUBSCRIPTION = 2,
}

export const TYPE_PRODUCT_OPTIONS: Option[] = [
  {
    label: `Type_Product_${PRODUCT_TYPE.TICKET}`,
    value: String(PRODUCT_TYPE.TICKET),
  },
  {
    label: `Type_Product_${PRODUCT_TYPE.SUBSCRIPTION}`,
    value: String(PRODUCT_TYPE.SUBSCRIPTION),
  },
];

export const STATUS_PARTICIPATION_CATEGORY_OPTIONS: Option[] = [
  {
    label: "Status_Media_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Media_1", // Active
    value: "1",
  }
];

export const STATUS_OFFICIAL_MEDIA_OPTIONS: Option[] = [
  {
    label: "Status_Official_Media_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Official_Media_1", // Active
    value: "1",
  }
];

export const STATUS_AUTO_SELECTED_OPTIONS: Option[] = [
  {
    label: "Status_Auto_Selected_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Auto_Selected_1", // Active
    value: "1",
  }
];

export enum DEFAULT_FLAG {
  ON = 1,
  OFF = 0,
}

export const FLAG_OPTIONS: Option[] = [
  {
    label: "Flag_0", // Of
    value: "0",
  },
  {
    label: "Flag_1", // On
    value: "1",
  }
];


export const DISPLAY_OPTIONS: Option[] = [
  {
    label: "ON", // ON
    value: "1",
  },
  {
    label: "OFF", // OF
    value: "0",
  },
];

export const COLORS_CONFIGURATION_STATUS = ["danger", "success"];

export const COLORS_STATUS_CAMPAIGN: any = {
  pending: "secondary",
  approved: "primary",
  completed: "success",
  rejected: "danger"
};

export const COLORS_CATEGORY_STATUS = ['secondary', 'warning', 'warning', 'warning', 'primary', 'success', 'danger', 'success'];


export const STATUS_CATEGORY_OPTIONS: Option[] = [
  {
    label: "Status_Category_1", // No Index
    value: "1",
  },
  {
    label: "Status_Category_2", // No Frequency
    value: "2",
  },
  {
    label: "Status_Category_3", // No Keyword
    value: "3",
  },
  {
    label: "Status_Category_4", // Collecting
    value: "4",
  },
  {
    label: "Status_Category_5", // Analyzing
    value: "5",
  },
  {
    label: "Status_Category_6", // Error
    value: "6",
  },
  {
    label: "Status_Category_7", // Success
    value: "7",
  }
];

export const COLORS_KEYWORD_STATUS: any = {
  '10': 'secondary',
  '20': 'warning',
  '30': 'warning',
  '40': 'primary',
  '50': 'success',
  '90': 'danger',
  '99': 'danger'
};

export const COLORS_GROUP_TYPE_CATEGORY_STATUS: any = {
  '1': 'warning',
  '2': 'primary',
  '3': 'success',
  '4': 'danger',
  '5': 'dark',
  '6': 'info',
};


export enum CATEGORY_GROUP_TYPE {
  PEOPLE = '1',
  BUSINESS = '2',
  CORPORATION = '3',
  CONTENTS = '4',
  GAME = '5',
  INSTITUTIONS = '6',
}

export const GROUP_TYPE_CATEGORY_OPTIONS: Option[] = [
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.PEOPLE}`,
    value: CATEGORY_GROUP_TYPE.PEOPLE,
  },
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.BUSINESS}`,
    value: CATEGORY_GROUP_TYPE.BUSINESS,
  },
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.CORPORATION}`,
    value: CATEGORY_GROUP_TYPE.CORPORATION,
  },
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.CONTENTS}`,
    value: CATEGORY_GROUP_TYPE.CONTENTS,
  },
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.GAME}`,
    value: CATEGORY_GROUP_TYPE.GAME,
  },
  {
    label: `Group_Type_Category_${CATEGORY_GROUP_TYPE.INSTITUTIONS}`,
    value: CATEGORY_GROUP_TYPE.INSTITUTIONS,
  },
];

export enum CATEGORY_RANK_TYPE {
  POPULAR = '2',
  ISSUE = '1',
}

export const TYPE_RANK_CATEGORY_OPTIONS: Option[] = [
  {
    label: `Type_Rank_Category_${CATEGORY_RANK_TYPE.POPULAR}`, // Popular
    value: CATEGORY_RANK_TYPE.POPULAR,
  },
  {
    label: `Type_Rank_Category_${CATEGORY_RANK_TYPE.ISSUE}`, // Issue
    value: CATEGORY_RANK_TYPE.ISSUE,
  },
];

export enum CATEGORY_CONFIG_HISTORY_TYPE {
  INDEX_CONFIG = '1',
  ISSUE_INDEX_CONFIG = '3',
  FREQUENCY = '2',
}

export const TYPE_CONFIG_HISTORY_CATEGORY_OPTIONS: Option[] = [
  {
    label: `Type_Config_History_Category_${CATEGORY_CONFIG_HISTORY_TYPE.INDEX_CONFIG}`, // Index
    value: CATEGORY_CONFIG_HISTORY_TYPE.INDEX_CONFIG,
  },
  {
    label: `Type_Config_History_Category_${CATEGORY_CONFIG_HISTORY_TYPE.ISSUE_INDEX_CONFIG}`, // Issue
    value: CATEGORY_CONFIG_HISTORY_TYPE.ISSUE_INDEX_CONFIG,
  },
  {
    label: `Type_Config_History_Category_${CATEGORY_CONFIG_HISTORY_TYPE.FREQUENCY}`, // Frequency
    value: CATEGORY_CONFIG_HISTORY_TYPE.FREQUENCY,
  },
];


export const STATUS_KEYWORD_INIT_OPTIONS: Option[] = [
  {
    label: "Status_Keyword_Init_10", // New
    value: "10",
  },
  {
    label: "Status_Keyword_Init_20", // Collecting
    value: "20",
  },
  {
    label: "Status_Keyword_Init_30", // Collected
    value: "30",
  },
  {
    label: "Status_Keyword_Init_40", // Analyzing
    value: "40",
  },
  {
    label: "Status_Keyword_Init_50", // Analyzed
    value: "50",
  },
  {
    label: "Status_Keyword_Init_90", // Keyword invalid
    value: "90",
  },
  {
    label: "Status_Keyword_Init_99", // Error
    value: "99",
  },
];


export const STATUS_KEYWORD_DAILY_OPTIONS: Option[] = [
  {
    label: "Status_Keyword_Daily_10", // Pending
    value: "10",
  },
  {
    label: "Status_Keyword_Daily_20", // Collecting
    value: "20",
  },
  {
    label: "Status_Keyword_Daily_30", // Collected
    value: "30",
  },
  {
    label: "Status_Keyword_Daily_40", // Analyzing
    value: "40",
  },
  {
    label: "Status_Keyword_Daily_50", // Analyzed
    value: "50",
  },
  {
    label: "Status_Keyword_Daily_90", // Keyword invalid
    value: "90",
  },
  {
    label: "Status_Keyword_Daily_99", // Error
    value: "99",
  },
];

export const FREQUENCY_TYPE: any = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export const INDEX_TYPE: any = {
  SEARCH_INDEX: 1,
  SOCIAL_INDEX: 2,
  VIRAL_INDEX: 3,
  NEWS_INDEX: 4,
};

export const FREQUENCY_OPTIONS: Option[] = [
  {
    label: "Frequency_1", // Daily
    value: String(FREQUENCY_TYPE.DAILY),
  },
  {
    label: "Frequency_2", // Weekly
    value: String(FREQUENCY_TYPE.WEEKLY),
  },
  {
    label: "Frequency_3", // Monthly
    value: String(FREQUENCY_TYPE.MONTHLY),
  },
];

export const STATUS_CONFIG_OPTIONS: Option[] = [
  {
    label: "Status_Configuration_0", // Inactive
    value: "0",
  },
  {
    label: "Status_Configuration_1", // Active
    value: "1",
  }
];

export const PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_naver",
    value: "naver",
  },
  {
    label: "Platform_google",
    value: "google",
  },
  {
    label: "Platform_etc",
    value: "etc",
  },
];

export const AD_PLATFORM_LOG_OPTIONS: Option[] = [
  {
    label: "Platform_ad_system",
    value: "ad_system",
  },
  {
    label: "Platform_coupang",
    value: "coupang",
  },
];

export const AD_TYPE_OPTIONS: Option[] = [
  {
    label: "Type_close",
    value: "close",
  },
  {
    label: "Type_click",
    value: "click",
  },
  {
    label: "Type_impression",
    value: "impression",
  },
  {
    label: "Type_request",
    value: "request",
  },
  {
    label: "Type_block",
    value: "block",
  },
];

export const REFERRAL_TYPE_OPTIONS: Option[] = [
  {
    label: "Referral_type_external",
    value: "external",
  },
  {
    label: "Referral_type_internal",
    value: "internal",
  },
  {
    label: "Referral_type_none",
    value: "none",
  },
];



export const DEVICE_OPTIONS: Option[] = [
  {
    label: "Device_pc",
    value: "pc",
  },
  {
    label: "Device_mobile",
    value: "mobile",
  },
];

export const TYPE_ADS_DEVICE_OPTIONS: Option[] = [
  {
    label: "Mobile",
    value: "Mobile",
  },
  {
    label: "PC",
    value: "PC",
  },
];

export const BOT_OPTIONS: Option[] = [
  {
    label: "Bot_0",
    value: '0',
  },
  {
    label: "Bot_1",
    value: '1',
  },
];

export const BROWSER_OPTIONS: Option[] = [
  {
    label: "Browser_Samsung",
    value: 'Samsung Browser',
  },
  {
    label: "Browser_Chrome",
    value: 'Chrome',
  },
  {
    label: "Browser_Safari",
    value: 'Safari',
  },
  {
    label: "Browser_Unknown_Browser",
    value: 'Unknown Browser',
  },
];


export const IS_OPTIONS: Option[] = [
  {
    label: "YES",
    value: "1",
  },
  {
    label: "NO",
    value: "0",
  },
];




export const OS_OPTIONS: Option[] = [
  {
    label: "OS_iOS",
    value: 'iOS',
  },
  {
    label: "OS_Mac",
    value: 'Mac',
  },
  {
    label: "OS_Windows",
    value: 'Windows',
  },
  {
    label: "OS_Android",
    value: 'Android',
  },
  {
    label: "OS_Etc",
    value: 'Etc',
  },
];


export const METHOD_OPTIONS: Option[] = [
  {
    label: "GET",
    value: "GET",
  },
  {
    label: "POST",
    value: "POST",
  },
  {
    label: "PUT",
    value: "PUT",
  },
  {
    label: "DELETE",
    value: "DELETE",
  },
];

export enum CPM_TYPE {
  MEDIA = 1,
  AGENCY = 2,
  INVENTORY = 3,
}

export enum TREND_INDEX_TYPE {
  SEARCH_INDEX = 1,
  SOCIAL_INDEX = 2,
  VIRAL_INDEX = 3,
  NEWS_INDEX = 4,
  STOCK_INDEX = 5,
  PARTICIPATION_INDEX = 6,
}



export enum CATEGORY_TYPE {
  TREND_INDEX = 1,
  STOCK = 2,
}

export const COLORS_STOCK_STATUS_CHANGE = ['warning', 'warning', 'danger', 'secondary', 'success', 'primary'];

export const TYPE_STOCK_OPTIONS: Option[] = [
  {
    label: "Type_Stock_1", // kospi
    value: "1",
  },
  {
    label: "Type_Stock_2", // kosdaq
    value: "2",
  },
];

export const STATUS_CHANGE_STOCK_OPTIONS: Option[] = [
  {
    label: "Status_Change_Stock_1", // Upper limit
    value: "1",
  },
  {
    label: "Status_Change_Stock_2", // Increase
    value: "2",
  },
  {
    label: "Status_Change_Stock_3", // No change
    value: "3",
  },
  {
    label: "Status_Change_Stock_4", // Lower limit
    value: "4",
  },
  {
    label: "Status_Change_Stock_5", // Decrease
    value: "5",
  },
];

export enum INDEX_COLLECT_METHOD {
  API = 'api',
  CRAWL = 'crawl',
}

export enum SUBSCRIPTION_PERIOD_TYPE {
  MONTHLY = 1,
  YEARLY = 2,
}

export enum CATEGORY_SOURCE_TYPE {
  DEFAULT = 1,
  MELON_CELEB = 2,
  DAUM_BASEBALL_PLAYER = 3,
  DAUM_GOLF_PLAYER = 4,
  DAUM_SOCCER_PLAYER = 5,
}

export const KEY_CATEGORY_SOURCE_TYPE: any = {
  [`${CATEGORY_SOURCE_TYPE.DEFAULT}`]: 'default',
  [`${CATEGORY_SOURCE_TYPE.MELON_CELEB}`]: 'singer',
  [`${CATEGORY_SOURCE_TYPE.DAUM_BASEBALL_PLAYER}`]: 'base_ball_player',
  [`${CATEGORY_SOURCE_TYPE.DAUM_GOLF_PLAYER}`]: 'golf_player',
  [`${CATEGORY_SOURCE_TYPE.DAUM_SOCCER_PLAYER}`]: 'soccer_player',
}

export enum KEYWORD_SOURCE_TYPE {
  DEFAULT = 1,
  STOCK = 2,
  SINGER = 3,
  ACTOR = 4,
  SOCCER_PLAYER = 5,
  BASE_BALL_PLAYER = 6,
  GOLF_PLAYER = 7,
  YOUTUBER = 8,
  GAME = 9,
  BOARD_CASTER = 10,
  INSTAGRAM = 11,
  NAVER_BLOG = 12,
  BRAND_CAR = 13,
  MODEL_CAR = 14,
  LOL_CHAMPION = 15,
  SYSTEM = 99,
}

export const COLOR_TYPE_SENTIMENT: any = {
  'Positive': 'primary',
  'Negative': 'danger',
  'Neutral': 'warning'
}

export const TYPE_CATEGORY_RANK_KEYWORD = {
  SINGER: '1',
  SPORT: '2',
  CAR: '3'
};

